import Cookies from 'js-cookie';
const authCookieName = 'vocabvault-token'

export const setToken = (token) => {
  const expireDate = new Date();
  expireDate.setFullYear(expireDate.getFullYear() + 100);
  Cookies.set(authCookieName, token, { expires: expireDate });
}

export const getToken = () => {
  return Cookies.get(authCookieName);
}