import React, { useState, useEffect } from 'react';
import { getDueCards, updateSRS, getWorstCards } from '../../util/apireq';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

// import { levenshtein } from '../../util/levenshtein.js';

import styles from './ReviewPage.module.css';
import button from '../../css/button.module.css';

const shuffleArray = (array) => { return array.sort(() => Math.random() - 0.5); };

function ReviewPage(){
  const navigate = useNavigate();
  const location = useLocation();
  const [cards, setCards] = useState([]);
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [reviewComplete, setReviewComplete] = useState(false);
  const [currentAnswer, setCurrentAnswer] = useState("");
  const [btnTtx, setBtnTxt] = useState("Check");
  const [showAnswers, setShowAnswers] = useState(false);
  const [inputFlashClass, setInputFlashClass] = useState("");
  const [mainErrorMsg, setMainErrorMsg] = useState("");

  const searchParams = new URLSearchParams(location.search);
  const reviewType = searchParams.get('type') || '';
  const qty = parseInt(searchParams.get('qty')) || 15;

  useEffect(() => {
    const fetchDueCards = async () => {
      if(reviewType !== "" && reviewType !== "worst"){ setMainErrorMsg("Invalid review type"); }
      if(!qty || qty < 1 || qty > 200){ setMainErrorMsg("Invalid quantity"); }
      if(reviewType === "worst"){
        const response = await getWorstCards(qty);
        if(response.error){
          if(response.error.msg === "invalid qty"){ setMainErrorMsg("Invalid quantity returned from server"); }
          return console.log(response);
        }
        const shuffledCards = shuffleArray(response.data);
        setCards(shuffledCards);
      } else {
        const response = await getDueCards();
        if(response.error){ return console.log(response); }
        const shuffledCards = shuffleArray(response.data);
        setCards(shuffledCards);
      }
      
    };
    fetchDueCards();
  }, []);

  const formatAnswer = (answer) => {
    return answer
      .trim() // Removes whitespace from both ends of the string
      .toLowerCase() // Converts to lowercase
      .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()'"]/g, "") // Removes punctuation
      .replace(/\s{2,}/g, " "); // Replaces multiple spaces with a single space
  };

  const checkCard = async () => {
    if(btnTtx === "Next"){
      const updatedCards = [...cards];
      const currentCard = updatedCards.splice(currentCardIndex, 1)[0];
      updatedCards.push(currentCard);
      setCards(updatedCards);
      setCurrentAnswer("");
      setShowAnswers(false);
      setBtnTxt("Check");
      nextCard(0);
      setInputFlashClass("");
    } else {
      const card = cards[currentCardIndex];
      let answerFormatted = formatAnswer(currentAnswer);
      let formattedAnswers = card.answers.map(formatAnswer);
      //const isCorrect = formattedAnswers.some((answer) => levenshteinDistance(answerFormatted, answer) <= 2);
      const isCorrect = formattedAnswers.includes(answerFormatted)
      if(isCorrect){
        setInputFlashClass(styles.flashGreen);
        setTimeout(() => { setInputFlashClass(""); }, 1000);
        if(reviewType !== "worst"){
          await updateSRS(card._id, true);
        }
        setCurrentAnswer("");
        setShowAnswers(false);
        nextCard(1);
      } else {
        setInputFlashClass(styles.flashRed);
        setTimeout(() => { setInputFlashClass(""); }, 1000);
        if(reviewType !== "worst"){
          await updateSRS(card._id, false);
        }
        setBtnTxt("Next");
        setShowAnswers(true);
      }
    }
  }

  const nextCard = (increment) => {
    if(currentCardIndex < cards.length - 1){
      setCurrentCardIndex(currentCardIndex + increment);
    } else {
      setReviewComplete(true);
    }
  };

  if(reviewComplete) { return navigate('/'); }

  return (
    <div className={styles.reviewPage}>
      {mainErrorMsg !== "" && (
        <h1>{mainErrorMsg}</h1>
      )}
      {cards.length > 0 ? (
        <div className={styles.container}>
          <div className={styles.card}>
            <h2>{cards[currentCardIndex].question}</h2>
            <div>
            <input 
              className={inputFlashClass} // Add this to apply the flash class
              placeholder='Answer...' 
              value={currentAnswer} 
              onChange={(e) => setCurrentAnswer(e.target.value)} 
              onKeyDown={(e) => { if(e.key === 'Enter'){ checkCard(); }}}
            ></input>
            </div>
            <div>
              <button className={`${button.main}`} onClick={ checkCard }>{btnTtx}</button>
            </div>
            <div className={styles.answersContainer}>
              <h1>Answers:</h1>
              {showAnswers && (
                cards[currentCardIndex].answers.map((answer, index) => (
                  <h2 key={index}>{answer}</h2>
                ))
              )}
            </div>
            <div>
              <p>{`Card ${currentCardIndex+1}/${cards.length}`}</p>
            </div>
          </div>
        </div>  
        
      ) : (
        <h2>No cards due for review!</h2>
      )}
    </div>
  );
}

export default ReviewPage;