import React from 'react';
import { Link } from 'react-router-dom';

import styles from './CardsPage.module.css';


function formatUnixTimestamp(unixTimestamp) {
  const date = new Date(unixTimestamp);

  // Get hours and determine AM/PM
  let hours = date.getHours();
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12 || 12;

  // Get day of the week, day of the month, and month
  const dayOfWeek = date.toLocaleString('en-US', { weekday: 'short' });
  const day = date.getDate();
  const month = date.toLocaleString('en-US', { month: 'short' });

  // Get ordinal suffix (st, nd, rd, th)
  const ordinalSuffix = (day) => {
    if (day > 3 && day < 21) return 'th'; // teens are all "th"
    switch (day % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  };

  // Format the final string
  return `${hours}${ampm} ${dayOfWeek} ${day}${ordinalSuffix(day)} ${month}`;
}


function Card({data}){

  return (
    <Link to={`/card/${data._id}`} className={styles.cardLink}>
      <div className={styles.card}>
        <div className={styles.questionContainer}>
          <h2 className={styles.questionTxt}>{data.question}</h2>
        </div>
        <div className={styles.answersContainer}>
          {data.answers.map((answer, index) => (
            <h2 className={styles.answerTxt} key={index}>{answer}</h2>
          ))}
        </div>
        <div className={styles.srsInfoContainer}>
          <h2>Next Review: {formatUnixTimestamp(data.nextReviewDate)}</h2>
          <h2>Ease Factor: {Math.round(data.easeFactor * 100) / 100}</h2>
        </div>
      </div>
    </Link>
    
  );
}

export default Card;