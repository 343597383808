// themeManager.js

import Cookies from 'js-cookie';

const themeCookieName = 'appThemes';
const selectedThemeCookieName = 'selectedTheme';

export function setAllThemesCookie(themes) {
  const expireDate = new Date();
  expireDate.setFullYear(expireDate.getFullYear() + 100);
  Cookies.set(themeCookieName, JSON.stringify(themes), { expires: expireDate });
}

export function getAllThemes() {
  const allThemes = Cookies.get(themeCookieName);
  return allThemes ? JSON.parse(allThemes) : [];
}

export function deleteThemeCookie(themeName) {
  const themes = getAllThemes().filter(theme => theme.name !== themeName);
  setAllThemesCookie(themes);
}

export function setSelectedTheme(themeName) {
  const expireDate = new Date();
  expireDate.setFullYear(expireDate.getFullYear() + 100);
  Cookies.set(selectedThemeCookieName, themeName, { expires: expireDate });
}

export function getSelectedTheme() {
  return Cookies.get(selectedThemeCookieName) || '';
}

export function applyTheme(themeName) {
  const theme = getAllThemes().find(theme => theme.name === themeName);
  if (theme) {
    document.documentElement.style.setProperty('--background-color', theme.backgroundColor);
    document.documentElement.style.setProperty('--text-color', theme.textColor);
    document.documentElement.style.setProperty('--secondary-color', theme.secondaryColor);
    document.documentElement.style.setProperty('--card-color', theme.cardColor);
    document.documentElement.style.setProperty('--question-font', theme.questionFont);
    document.documentElement.style.setProperty('--regular-font', theme.regularFont);
    setSelectedTheme(themeName);
  }
}
