import React from 'react';
import styles from './CardsPage.module.css';
import button from '../../css/button.module.css'

function PageController({ currentPage, totalPages, onPageChange }) {

  const handlePrevious = () => {
      if(currentPage > 1) {
          onPageChange(currentPage - 1);
      }
  };

  const handleNext = () => {
      if(currentPage < totalPages) {
          onPageChange(currentPage + 1);
      }
  };

  return (
    <div className={styles.PageController}>
      <button
        className={button.main}
        onClick={handlePrevious} 
        disabled={currentPage === 1} 
      >
        &lt;
      </button>
      <span className={styles.pageInfo}>
        Page {currentPage}/{totalPages}
      </span>
      <button 
        className={button.main}
        onClick={handleNext} 
        disabled={currentPage === totalPages} 
      >
        &gt;
      </button>
    </div>
  );
}

export default PageController;
