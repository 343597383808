import axios from 'axios';
import { getToken } from './auth.js';

const apiurl = "https://vocabvault.gorillaa.net/vvapi/" //process.env.REACT_APP_API_URL;

const handleError = async (error) => {
  if(!error.response){
    return {error: true, msg: "Unable to contact server"};
  }
  if(error.response.status === 400){
    return {error: true, msg: "Invalid card ID"};
  }
  if(error.response.status === 401){
    window.location = '/account'
  }
  return {error: true, msg: error.response.data.msg};
};


export const getCards = async (search, page) => {
  try {
    const token = getToken();
    const response = await axios.get(`${apiurl}/cards/getpage`, {
      headers: {
        Authorization: `${token}`,
      },
      params: {
        search: search,
        page: page,
      }
    });
    return response;
  } catch (error) {
    return handleError(error);
  }
};

export const getCard = async (id) => {
  try {
    const token = getToken();
    const response = await axios.get(`${apiurl}/cards/getsingle`, {
      headers: {
        Authorization: `${token}`,
      },
      params: {
        id: id,
      }
    });
    return response;
  } catch (error) {
    return handleError(error);
  }
};

export const addCardReq = async (question, answers) => {
  try {
    const token = getToken();
    const response = await axios.post(`${apiurl}/cards/addcard`, {
      token,
      question,
      answers
    });
    return response;
  } catch (error) {
    return handleError(error);
  }
};

export const updateCard = async (id, question, answers, dateNow, easeFactor, interval, repetitions) => {
  try {
    const token = getToken();
    const response = await axios.post(`${apiurl}/cards/updatecard`, {
      token,
      id,
      question,
      answers,
      dateNow,
      easeFactor,
      interval,
      repetitions
    });
    return response;
  } catch (error) {
    return handleError(error);
  }
};

export const deleteCard = async (id, question, answers) => {
  try {
    const token = getToken();
    const response = await axios.post(`${apiurl}/cards/deletecard`, {
      token,
      id
    });
    return response;
  } catch (error) {
    return handleError(error);
  }
};

export const getDueCards = async () => {
  try {
    const token = getToken();
      const response = await axios.get(`${apiurl}/cards/getdue`, {
        headers: {
          Authorization: `${token}`,
        }
      });
      return response;
    } catch (error) {
      return handleError(error);
    }
};

export const updateSRS = async (id, correct) => {
  try {
    const token = getToken();
    const response = await axios.post(`${apiurl}/cards/updatesrs`, {
      token,
      id,
      correct
    });
    return response;
  } catch (error) {
    return handleError(error);
  }
};

export const getUserSettings = async () => {
  try {
    const token = getToken();
      const response = await axios.get(`${apiurl}/user/getsettings`, {
        headers: {
          Authorization: `${token}`,
        }
      });
      return response;
    } catch (error) {
      return handleError(error);
    }
};

export const updateUserSettings = async (hoursTillCardReset) => {
  try {
    const token = getToken();
    const response = await axios.post(`${apiurl}/user/updatesettings`, {
      token,
      hoursTillCardReset
    });
    return response;
  } catch (error) {
    return handleError(error);
  }
};

export const getWorstCards = async (qty) => {
  try {
    const token = getToken();
    const response = await axios.get(`${apiurl}/cards/getworst`, {
      headers: {
        Authorization: `${token}`,
      },
      params: {
        qty: qty,
      }
    });
    return response;
  } catch (error) {
    return handleError(error);
  }
};