import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Navbar.module.css';

const Navbar = () => {
  return (
    <div className={styles.fake}>
      <nav className={styles.navbar}>
        <div className={styles.navbargroup}>
          <Link to="/" className={styles.navItem}>
            🏠
          </Link>
          <Link to="/cards" className={styles.navItem}>
            🃏
          </Link>
        </div>

        <div className={styles.navbargroup}>
          <Link to="/account" className={styles.navItem}>
            🧍‍♂️
          </Link>
          <Link to="/settings" className={`${styles.navItem} ${styles.gear}`}>
            ⚙️
          </Link>
        </div>
        
        
      </nav>
    </div>
    
  );
};

export default Navbar;
