import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import useScrollToTop from './util/scrollToTop.js';
import { getSelectedTheme, applyTheme } from './util/themeManager.js';

import Navbar from './util/navbar/Navbar.js';
import HomePage from './pages/home/HomePage.js';
import SettingsPage from './pages/settings/SettingsPage.js';
import CardsPage from './pages/cards/CardsPage.js';
import CardPage from './pages/card/CardPage.js';
import AccountPage from './pages/account/AccountPage.js';
import ReviewPage from './pages/review/ReviewPage.js';



function Wrapper() {

	useScrollToTop();
	applyTheme(getSelectedTheme());

	return (
		<div>
			<Navbar></Navbar>

			<Routes>
				<Route path="/" element={<HomePage/>}/>
				<Route path="/settings" element={<SettingsPage/>}/>
				<Route path="/cards" element={<CardsPage/>}/>
				<Route path="/card/:id" element={<CardPage/>}/>
				<Route path="/account" element={<AccountPage/>}/>
				<Route path="/review" element={<ReviewPage/>}/>
			
			</Routes>
		</div>
			
	);
}

function App() {
	return (
		<Router>
			<Wrapper/>
		</Router>
	);
}

export default App;
