import React, { useEffect, useState } from 'react';

import styles from './AccountPage.module.css';
import button from '../../css/button.module.css';

import { getToken, setToken } from '../../util/auth.js';

function AccountPage() {

  const [tokenShown, setTokenShown] = useState(false);
  const [tokenValue, setTokenValue] = useState('');

  useEffect(() => {
    const tokenFetched = getToken();
    if (tokenFetched) {
      setTokenValue(tokenFetched);
    }
  }, []);

  const saveToken = () => {
    setToken(tokenValue);
    setTokenShown(false);
  };

  return (
    <div className={styles.accountpage}>
      <h1>Account Page</h1>
      <button className={button.main} onClick={() => setTokenShown(true)}>Edit Token</button>
      <div>
        {tokenShown ? (
          <div>
            <input
              type="text"
              value={tokenValue}
              onChange={(e) => setTokenValue(e.target.value)}
            />
            <button className={button.main} onClick={saveToken}>Save</button>
            <p>{tokenValue ? `Token: ${tokenValue}` : 'No token saved'}</p>
          </div>
        ) : (
          <p>...</p>
        )}
      </div>
    </div>
  );
}

export default AccountPage;