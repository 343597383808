import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getDueCards } from '../../util/apireq';

import styles from './HomePage.module.css';
import button from '../../css/button.module.css';

function HomePage(){
  const navigate = useNavigate();
  const [loadedReviewStackCount, setLoadedReviewStackCount] = useState(false);
  const [reviewStackCount, setReviewStackCount] = useState(0);
  const [worstReviewQuantity, setWorstReviewQuantity] = useState(10);

  useEffect(() => {
    const fetchDueCards = async () => {
      const response = await getDueCards();
      if(response.error) {
        if(response.error.status === 401){
          return navigate('/account');
        }
        return console.log(response)
      }
      setReviewStackCount(response.data.length);
      setLoadedReviewStackCount(true);
    };
    fetchDueCards();
  })

  return (
    <div className={styles.homepage}>
      <h1>ようこそ！</h1>
      {loadedReviewStackCount ? (
        <div className={styles.reviewStack}>
          <h2>{`Review Stack: ${reviewStackCount} items`}</h2>
          <Link to={`/review`}>
            <button className={button.main}>Begin</button>
          </Link>
        </div>
      ) : (
        <div></div>
      )}
      
      <div className={styles.reviewStack}>
        <h2>Practice Worst Cards (won't affect SRS values)</h2>
        <div className={styles.quantityDiv}>
          <label>Quantity: </label>
          <input 
            type='number'
            value={worstReviewQuantity}
            onChange={(e) => setWorstReviewQuantity(e.target.value)}
          ></input>
        </div>
        <Link to={`/review?type=worst&qty=${worstReviewQuantity}`}>
          <button className={button.main}>Begin</button>
        </Link>
      </div>
      <div className={styles.reviewStack}>
        <h2>Practice Random Cards</h2>
        <button className={button.main}>Begin (inop)</button>
      </div>
    </div>
  );
}

export default HomePage;