import React, { useState, useEffect } from 'react';
import { getUserSettings, updateUserSettings } from '../../util/apireq';
import { useNavigate } from 'react-router-dom';
import styles from './AccountSettings.module.css';
import button from '../../css/button.module.css';


const AccountSettings = () => {
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);
  const [hoursTillCardReset, setHoursTillCardReset] = useState(0);
  const [savedTxt, setSavedTxt] = useState("");

  useEffect(() => {
    const getSettings = async () => {
      const response = await getUserSettings();
      if(response.error){
        if(response.error.status === 401){
          return navigate('/account');
        }
        return;
      }
      setHoursTillCardReset(response.data.hoursTillCardReset);
      setLoaded(true);
      console.log(response)
    }
    getSettings();
  }, []);


  const saveChanges = async () => {
    const response = await updateUserSettings(hoursTillCardReset);
    if(response.error){
      setSavedTxt(response.msg)
      return;
    }
    setSavedTxt("Saved");
  }

  return (
    <div className={styles.accountsettings}>
      {loaded ? (
        <div>
          <div>
            <label>Additional hours added to card review date</label>
            <input value={hoursTillCardReset} onChange={(e) => setHoursTillCardReset(e.target.value)} type='number'></input>
            <p>This will be added in addition to whatever the SRS algorithm adds to the date. user.hoursTillCardReset is this value. 24 hours is recommended, maybe 20 if you want to study more or 28 if you want to study less. This will only apply from now on, not to existing cards.</p>
            <div><code>{`if(correct) {`}</code></div>
            <div><code style={{paddingLeft: '20px'}}>{`repetitions += 1;`}</code></div>
            <div><code style={{paddingLeft: '20px'}}>{`easeFactor = Math.max(1.3, easeFactor - 0.1 + (0.1 * repetitions));`}</code></div>
            <div><code style={{paddingLeft: '20px'}}>{`interval = Math.round(interval * easeFactor);`}</code></div>
            <div><code style={{paddingLeft: '20px'}}>{`nextReviewDate = Date.now() + (interval * user.hoursTillCardReset * 60 * 60 * 1000);`}</code></div>
            <div><code>{`} else {`}</code></div>
            <div><code style={{paddingLeft: '20px'}}>{`interval = 1;`}</code></div>
            <div><code style={{paddingLeft: '20px'}}>{`easeFactor = Math.max(1.3, easeFactor - 0.2);`}</code></div>
            <div><code>{`}`}</code></div>
          </div>
          <button className={button.main} style={{marginTop: "20px"}} onClick={saveChanges}>Save Changes</button>
          {savedTxt !== "" ? (
            <p>{savedTxt}</p>
          ) : (
            <div></div>
          )}
        </div>
        
      ) : (
        <div></div>
      )}
      
    </div>
  );
};

export default AccountSettings;