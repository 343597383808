import { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';

import { getCard, updateCard, deleteCard, addCardReq } from '../../util/apireq';

import styles from './CardPage.module.css';
import button from '../../css/button.module.css';


  
function JournalEntry(){
  const { id } = useParams();
  const [newEntry, setNewEntry] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [loading, setLoading] = useState(true);

  const [responseTxtLoadng, setResponseTxtLoading] = useState(false);
  const [responseTxt, setResponseTxt] = useState("");

  const [card, setCard] = useState({});

  const [easeFactor, setEaseFactor] = useState(0);
  const [intervalNOTJS, setIntervalNOTJS] = useState(0);
  const [repetitions, setRepetitions] = useState(0);

  const [question, setQuestion] = useState('');
  const [answers, setAnswers] = useState(['']);

  


  function formatUnixTimestamp(unixTimestamp) {
    const date = new Date(unixTimestamp);
  
    // Get hours and determine AM/PM
    let hours = date.getHours();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12 || 12;
  
    // Get minutes
    const minutes = date.getMinutes();
    const minutesStr = minutes < 10 ? `0${minutes}` : minutes;
  
    // Get day of the week, day of the month, and month
    const dayOfWeek = date.toLocaleString('en-US', { weekday: 'short' });
    const day = date.getDate();
    const month = date.toLocaleString('en-US', { month: 'short' });
  
    // Get ordinal suffix (st, nd, rd, th)
    const ordinalSuffix = (day) => {
      if (day > 3 && day < 21) return 'th'; // teens are all "th"
      switch (day % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
      }
    };
  
    // Format the final string
    return `${hours}:${minutesStr}${ampm} ${dayOfWeek} ${day}${ordinalSuffix(day)} ${month}`;
  }

  // setup new or existing entry
  useEffect(() => {
    if (id === 'new') {
      setNewEntry(true);
    } else {
      setLoading(true);
      const fetch = async () => {
        try {
          const response = await getCard(id);
          if (response.error) {
            setLoading(false);
            setNotFound(true);
            return;
          }
          const fetchedCard = response.data;
          setCard(fetchedCard);
          setEaseFactor(Math.round(fetchedCard.easeFactor * 100) / 100);
          setRepetitions(fetchedCard.repetitions);
          setIntervalNOTJS(fetchedCard.interval)
          setQuestion(fetchedCard.question);
          let filteredAnswers = fetchedCard.answers.filter(answer => answer.trim() !== '');
          filteredAnswers.push('');
          setAnswers(filteredAnswers);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          setNotFound(true);
          console.error("Failed to fetch cards:", error);
        }
      }
      fetch();
      
    }
  }, [id]);



  // Handle question change
  const handleQuestionChange = (e) => {
    setQuestion(e.target.value);
  };

  // Handle answer change
  const handleAnswerChange = (index, e) => {
    const newAnswers = [...answers];
    newAnswers[index] = e.target.value;

    if (index === answers.length - 1 && e.target.value !== '') {
      // Add a new input if the last input has content
      newAnswers.push('');
    }
    setAnswers(newAnswers);
  };

  // Handle input blur event to clean up empty answers
  const handleAnswerBlur = () => {
    const cleanedAnswers = answers.filter(answer => answer.trim() !== '');
    if (cleanedAnswers.length === 0 || cleanedAnswers[cleanedAnswers.length - 1] !== '') {
      cleanedAnswers.push(''); // Ensure there's always one empty input at the end
    }
    setAnswers(cleanedAnswers);
  };


  const handleUpdate = async () => {
    setResponseTxtLoading(true);
    const filteredAnswers = answers.filter(answer => answer.trim() !== '');
    const response = await updateCard(id, question, filteredAnswers, false, easeFactor, intervalNOTJS, repetitions);
    if(response.error){
      setResponseTxtLoading(false);
      setResponseTxt(response.msg);
    }
    if(response.status === 200){
      setResponseTxtLoading(false);
      setResponseTxt("Successfully Updated");
    }
  }

  const handleDelete = async () => {
    setResponseTxtLoading(true);
    const response = await deleteCard(id);
    if(response.error){
      setResponseTxtLoading(false);
      setResponseTxt(response.msg);
    }
    if(response.status === 200){
      setResponseTxtLoading(false);
      setResponseTxt("Successfully Deleted");
    }
  }

  const handleSave = async () => {
    const filteredAnswers = answers.filter(answer => answer.trim() !== '');
    const response = await addCardReq(question, filteredAnswers);
    if(response.error){
      setResponseTxtLoading(false);
      setResponseTxt(response.msg);
    }
    if(response.status === 200){
      setResponseTxtLoading(false);
      setResponseTxt("Successfully Created");
    }
  }

  const dateNow = async () => {
    setResponseTxtLoading(true);
    const filteredAnswers = answers.filter(answer => answer.trim() !== '');
    const response = await updateCard(id, question, filteredAnswers, true, easeFactor, intervalNOTJS, repetitions);
    if(response.error){
      setResponseTxtLoading(false);
      setResponseTxt(response.msg);
    }
    if(response.status === 200){
      window.location.reload();
    }
  }

  
  return (
    <div className={styles.outerdiv}>
      <div className={styles.backBtn}>
        <Link to="/cards">
          <h2>← Back</h2>
        </Link>
      </div>
  
      {newEntry ? (
        <div className={styles.newCardContainer}>
          <h2 className={styles.createANewCard}>Create a New Card</h2>
          <div className={styles.cardContainer}>
            <div className={styles.card}>
              <div className={styles.questionContainer}>
                <textarea
                  className={styles.questionTxt}
                  value={question}
                  onChange={handleQuestionChange}
                />
              </div>
              <h3 className={styles.answersName}>Answers</h3>
              <div className={styles.answersContainer}>
                {answers.map((answer, index) => (
                  <input
                    key={index}
                    type="text"
                    className={styles.answerInput}
                    value={answer}
                    onChange={(e) => handleAnswerChange(index, e)}
                    onBlur={handleAnswerBlur}
                    placeholder={index === answers.length - 1 ? 'Add answer' : ''}
                  />
                ))}
              </div>
              <div className={styles.srsInfoContainer}>
                <h2>Next Review: After this has been created</h2>
              </div>
            </div>
          </div>
          <div className={styles.btnsContainer}>
            <button className={`${button.main}`} onClick={ handleSave }>Save</button>
          </div>
          <div className={styles.resultTxtContainer}>
            {responseTxtLoadng ? (
              <l-ring-2 size="40" stroke="5" stroke-length="0.25" bg-opacity="0.1" speed="0.8" color="white"></l-ring-2>
            ) : (
              <h2>{responseTxt}</h2>
            )}
          </div>
        </div>
      ) : (
        loading ? (
          <div className={styles.loadingContainer}>
            <l-ring-2 size="40" stroke="5" stroke-length="0.25" bg-opacity="0.1" speed="0.8" color="white"></l-ring-2>
          </div>
        ) : (
          notFound ? (
            <div className={styles.notFoundContainer}>
              <h2>Card Not Found</h2>
            </div>
          ) : (
            <div>
              <div className={styles.cardContainer}>
                <div className={styles.card}>
                  <div className={styles.questionContainer}>
                    <textarea
                      className={styles.questionTxt}
                      value={question}
                      onChange={handleQuestionChange}
                    />
                  </div>
                  <h3 className={styles.answersName}>Answers</h3>
                  <div className={styles.answersContainer}>
                    {answers.map((answer, index) => (
                      <input
                        key={index}
                        type="text"
                        className={styles.answerInput}
                        value={answer}
                        onChange={(e) => handleAnswerChange(index, e)}
                        onBlur={handleAnswerBlur}
                        placeholder={index === answers.length - 1 ? 'Add answer' : ''}
                      />
                    ))}
                  </div>
                  <div className={styles.srsInfoContainer}>
                    <h2>Next Review: {formatUnixTimestamp(card.nextReviewDate)}</h2>
                    <div>
                      <label>Ease Factor: </label><input value={easeFactor} onChange={(e) => setEaseFactor(e.target.value)} type='number'></input>
                    </div>
                    <div>
                      <label>Interval: </label><input value={intervalNOTJS} onChange={(e) => setIntervalNOTJS(e.target.value)} type='number'></input>
                    </div>
                    <div>
                      <label>Repetitions: </label><input value={repetitions} onChange={(e) => setRepetitions(e.target.value)} type='number'></input>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.btnsContainer}>
                <button className={`${button.main}`} onClick={ dateNow }>Date Now</button>
                <button className={`${button.main}`} onClick={ handleUpdate }>Update</button>
                <button className={`${button.main}`} onClick={ handleDelete }>Delete</button>
              </div>
              <div className={styles.resultTxtContainer}>
                {responseTxtLoadng ? (
                  <l-ring-2 size="40" stroke="5" stroke-length="0.25" bg-opacity="0.1" speed="0.8" color="white"></l-ring-2>
                ) : (
                  <h2>{responseTxt}</h2>
                )}
              </div>
            </div>
          )
        )
      )}
    </div>
  );
}

export default JournalEntry;
