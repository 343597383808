import React, { useState, useEffect } from 'react';
import styles from './Theme.module.css';
import {
  setAllThemesCookie,
  getAllThemes,
  applyTheme,
  setSelectedTheme,
  getSelectedTheme,
} from '../../util/themeManager.js';

const defaultThemes = [
  {
    name: 'Dark Theme',
    backgroundColor: '#111111',
    textColor: '#FFFFFF',
    secondaryColor: '#333333',
    cardColor: '#282830',
    questionFont: 'Arial',
    regularFont: 'Arial',
    isDefault: true,
  },
  {
    name: 'Light Theme',
    backgroundColor: '#FFFFFF',
    textColor: '#000000',
    secondaryColor: '#EEEEEE',
    cardColor: '#CCCCCC',
    questionFont: 'Arial',
    regularFont: 'Arial',
    isDefault: true,
  },
];

const fonts = ['Arial', 'Verdana', 'Times New Roman', 'Georgia', 'Courier New'];

const Theme = () => {
  const [themes, setThemes] = useState(() => {
    const storedThemes = getAllThemes();
    return storedThemes.length > 0 ? storedThemes : defaultThemes;
  });
  
  // Add the missing state for newThemeName
  const [newThemeName, setNewThemeName] = useState('');

  const [selectedTheme, setSelectedThemeState] = useState(() => {
    const storedSelectedTheme = getSelectedTheme();
    return storedSelectedTheme || 'Dark Theme';  // Default to 'Dark Theme' if none is selected
  });

  useEffect(() => {
    // Apply the selected theme when the component first mounts
    applyTheme(selectedTheme);

    // If this is the first load and no themes are stored, set cookies for default themes and selected theme
    if (!getAllThemes().length) {
      setAllThemesCookie(defaultThemes);
      setSelectedTheme('Dark Theme');
    }
  }, [selectedTheme]);

  const addTheme = () => {
    const newTheme = {
      name: newThemeName || `New Theme ${themes.length + 1}`,
      backgroundColor: '#FFFFFF',
      textColor: '#000000',
      secondaryColor: '#EEEEEE',
      cardColor: '#CCCCCC',
      questionFont: 'Arial',
      regularFont: 'Arial',
      isDefault: false,
    };
    const updatedThemes = [...themes, newTheme];
    setThemes(updatedThemes);
    setAllThemesCookie(updatedThemes);
    setNewThemeName(''); // Reset the new theme name input
  };

  const updateThemeProperty = (index, property, value) => {
    const updatedThemes = [...themes];
    updatedThemes[index][property] = value;
    setThemes(updatedThemes);
    setAllThemesCookie(updatedThemes);
  };

  const deleteTheme = (index) => {
    const updatedThemes = themes.filter((_, i) => i !== index);
    setThemes(updatedThemes);
    setAllThemesCookie(updatedThemes);
  };

  const handleThemeSelection = (themeName) => {
    setSelectedThemeState(themeName);
    applyTheme(themeName);
  };

  return (
    <div className={styles.themeContainer}>
      {themes.map((theme, index) => (
        <div key={index} className={styles.theme}>
          <input
            type="radio"
            checked={selectedTheme === theme.name}
            onChange={() => handleThemeSelection(theme.name)}
          />
          <input
            type="text"
            className={styles.themeNameInput}
            value={theme.name}
            onChange={(e) => updateThemeProperty(index, 'name', e.target.value)}
            disabled={theme.isDefault}
          />
          <div className={styles.themeProperty}>
            <label>Background Color:</label>
            <input
              type="color"
              className={styles.colorPicker}
              value={theme.backgroundColor}
              onChange={(e) => updateThemeProperty(index, 'backgroundColor', e.target.value)}
              disabled={theme.isDefault}
            />
          </div>
          <div className={styles.themeProperty}>
            <label>Text Color:</label>
            <input
              type="color"
              className={styles.colorPicker}
              value={theme.textColor}
              onChange={(e) => updateThemeProperty(index, 'textColor', e.target.value)}
              disabled={theme.isDefault}
            />
          </div>
          <div className={styles.themeProperty}>
            <label>Secondary Color:</label>
            <input
              type="color"
              className={styles.colorPicker}
              value={theme.secondaryColor}
              onChange={(e) => updateThemeProperty(index, 'secondaryColor', e.target.value)}
              disabled={theme.isDefault}
            />
          </div>
          <div className={styles.themeProperty}>
            <label>Card Color:</label>
            <input
              type="color"
              className={styles.colorPicker}
              value={theme.cardColor}
              onChange={(e) => updateThemeProperty(index, 'cardColor', e.target.value)}
              disabled={theme.isDefault}
            />
          </div>
          <div className={styles.themeProperty}>
            <label>Question Font:</label>
            <select
              className={styles.fontDropdown}
              value={theme.questionFont}
              onChange={(e) => updateThemeProperty(index, 'questionFont', e.target.value)}
            >
              {fonts.map((font, fontIndex) => (
                <option key={fontIndex} value={font}>
                  {font}
                </option>
              ))}
            </select>
          </div>
          <div className={styles.themeProperty}>
            <label>Regular Font:</label>
            <select
              className={styles.fontDropdown}
              value={theme.regularFont}
              onChange={(e) => updateThemeProperty(index, 'regularFont', e.target.value)}
            >
              {fonts.map((font, fontIndex) => (
                <option key={fontIndex} value={font}>
                  {font}
                </option>
              ))}
            </select>
          </div>
          {!theme.isDefault && (
            <button onClick={() => deleteTheme(index)} className={styles.deleteButton}>
              Delete Theme
            </button>
          )}
        </div>
      ))}
      <div>
        <input
          type="text"
          placeholder="New Theme Name"
          value={newThemeName}
          onChange={(e) => setNewThemeName(e.target.value)}
          className={styles.themeNameInput}
        />
        <button onClick={addTheme} className={styles.addThemeButton}>
          + Add Theme
        </button>
      </div>
    </div>
  );
};

export default Theme;