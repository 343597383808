import React, { useEffect, useState } from 'react';
import { ring2 } from 'ldrs';
import { getCards } from '../../util/apireq.js';
import { useNavigate } from 'react-router-dom';
import PageController from './PageController.js';



// Default values shown


import styles from './CardsPage.module.css';
import buttons from '../../css/button.module.css';

import Card from './Card.js';

function CardsPage(){
  const navigate = useNavigate();
  ring2.register()

  const [selectedPage, setSelectedPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const [cards, setCards] = useState([]);

  // fetch cards
  useEffect(() => {
    const debounceTimeout = setTimeout(async () => {
      setLoading(true);
      try {
        const response = await getCards(searchTerm, selectedPage);
        if (response.error) {
          return;
        }
        console.log(response.data)
        setCards(response.data.results);
        setTotalPages(response.data.totalPages)
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Failed to fetch cards:", error);
      }
    }, 200);

    return () => clearTimeout(debounceTimeout);
  }, [searchTerm, selectedPage]);

  // search input change
  const handleSearchInputChange = (event) => {
    setSearchTerm(event.target.value);
    setSelectedPage(1);
  };

  const handleAddCard = () => {
    navigate('/card/new');
  }

  const handlePageChange = (newPage) => {
    setSelectedPage(newPage);
  };

  return (
    <div className={styles.cardspage}>
      <div className={styles.topBtns}>
        <h1>カード</h1>
        <button className={`${buttons.main} ${styles.plusBtn}`} onClick={ handleAddCard }>+</button>
      </div>

      <div className={styles.searchBarContainer}>
        <input
          type="text"
          className={styles.searchBar}
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearchInputChange}
        />
      </div>


      { loading ? (
        <div className={styles.loadingContainer}>
          <l-ring-2 size="40" stroke="5" stroke-length="0.25" bg-opacity="0.1" speed="0.8" color="white"></l-ring-2>
        </div>
      ) : (
        <div className={styles.content}>
          <div className={styles.cardsContainer}>
            {cards.map((card, index) => (
              <Card data={card} key={index}/>
            ))}
          </div>
        </div>
      )}

      {totalPages > 1 && (
        <PageController
            totalPages={totalPages}
            currentPage={selectedPage}
            onPageChange={handlePageChange}
        />
      )}

      
      
    </div>
  );
}

export default CardsPage;