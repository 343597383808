import React from 'react';

import styles from './SettingsPage.module.css';

import AccountSettings from './AccountSettings.js';
import Theme from './Theme.js';


function SettingsPage(){

  return (
    <div className={styles.settingspage}>
      <h2>Account</h2>
      <AccountSettings></AccountSettings>
      <h2>Themes (refresh page to see modified theme)</h2>
      <Theme></Theme>
    </div>
  );
}

export default SettingsPage;